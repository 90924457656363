import '../../kyt/user/user.scss'
import './case.scss'
import { useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import Spinner from '../../../components/common/spinner/spinner'
import {
  Box,
  Button,
  InputLabel,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  Link,
  Paper,
  Fab,
  Chip,
  Tab,
  MenuItem,
  Select,
  Divider,
} from '@mui/material'
import { renderNames } from '../../../components/common/toRender/renderNames'
import { useDispatch, useSelector } from 'react-redux'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined'
import DatatableCaseTransaction from '../../../components/common/datatable/DatatableCaseTransaction'
import { useCaseAdminPanel, useCaseDetails } from '../../pages-hooks/useCases'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import DatatableUserCases from '../../../components/common/datatable/DatatableUserCases'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { TableChip } from '../../../components/utilities/TableChip'
import DatatableChecklistInstances from '../../../components/common/datatable/DatatableChechlistInstances'
import { updateCaseDetails } from '../../../features/cases/caseSlice'
import { useTranslation } from 'react-i18next'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { CaseStatus } from '../case.status'
import GeneralAlertsDatatable from '../alerts/GeneralAlertsTable'
import { KybChip } from '../../../components/utilities/KybChip'
import RichTextEditor from './RichTextEditor'
import { StatusIndicator } from '../../../components/utilities/StatusIndicator'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FileUploader } from 'react-drag-drop-files'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import CaseToPdf from './CaseToPdf'
import { useWorkflows } from '../../pages-hooks/useWorkflows'
import DatatableAssignRoles from '../../../components/common/datatable/DatatableAssignRoles'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import toaster from '../../../toaster'

const userStateValues = ['ACTIVE', 'BLOCKED', 'CREATED', 'DORMANT', 'SUSPENDED', 'TERMINATED', 'UNACCEPTABLE']

const Case = () => {
  const { theme } = useSelector((state) => state.theme)
  const { id } = useParams()
  const { accessToken, email, emailClient, activeServices, roles } = useSelector((state) => state.login)
  const [data, setData] = useState()
  const [initialLoading, setInitialLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [newCommentMessage, setNewCommentMessage] = useState('')
  const [openCloseCase, setOpenCloseCase] = useState(false)
  const [openUserAction, setOpenUserAction] = useState(false)
  const [caseToCloseComment, setCaseToCloseComment] = useState('')
  const [caseToOpenComment, setCaseToOpenComment] = useState('')
  const [status, setStatus] = useState('CLOSED_WITHOUT_ACTIONS')
  const [label, setLabel] = useState('')
  const [category, setCategory] = useState('')
  const [viewToast, setViewToast] = useState(false)
  const [message, setMessage] = useState('')
  const [isEditingState, setIsEditingState] = useState(false)
  const [userState, setUserState] = useState('')
  const [assignedRoles, setAssignedRoles] = useState([])
  const [checklists, setChecklists] = useState([])
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [errorAssign, setErrorAssign] = useState(false)
  const [isProcessingFile, setIsProcessingFile] = useState(false)
  const [processingError, setProcessingFileError] = useState(false)
  const [hasPermissions, setHaspermissions] = useState(false)
  const [isAssigned, setIsAssigned] = useState(false)
  const [timeline, setTimeline] = useState([])
  const [report, setReport] = useState('')
  const [lastReport, setLastReport] = useState('')
  const [reports, setReports] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedTab, setSelectedTab] = useState('cases')
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [modalReport, setOpenModalReport] = useState(false)
  const [workflowModal, setWorkflowModal] = useState(false)
  const [scaleModal, setScaleModal] = useState(false)
  const [selectedReport, setSelectedReport] = useState('')
  const [workflow, setWorkflow] = useState('')
  const [assignedWorkflow, setAssignedWorkflow] = useState('')
  const [actualStep, setActualStep] = useState(undefined)
  const [isFinalStep, setIsFinalStep] = useState(false)
  const [isInitialStep, setIsInitialStep] = useState(false)
  const [isDownscale, setIsDownscale] = useState(false)
  const [closeCaseModal, setCloseCaseModal] = useState(false)
  const [openCaseModal, setOpenCaseModal] = useState(false)
  const [caseIsClosed, setCaseIsClosed] = useState(false)
  const [caseStatusFinal, setCaseStatusFinal] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const [aux, setAux] = useState(false)
  const workflows = useWorkflows(setIsLoading, isLoading)
  const rolesState = useSelector((state) => state.role?.roles)

  useCaseDetails(setInitialLoading, initialLoading, id)
  useCaseAdminPanel(setInitialLoading, initialLoading)
  useRolesByEmailClient(emailClient, initialLoading, setInitialLoading)

  const handleChange = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue)
    },
    [setSelectedTab],
  )

  const caseDetailsSlice = useSelector((state) => state.case)

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })

  useEffect(() => {
    if (caseDetailsSlice?.caseAdminPanel?.statusAccess && roles) {
      const allowedStatuses = CaseStatus.filter((status) => {
        const rolesForStatus = caseDetailsSlice?.caseAdminPanel?.statusAccess[status]
        return (
          Array.isArray(rolesForStatus) &&
          (!rolesForStatus.length || roles.some((role) => rolesForStatus.includes(role)))
        )
      })
      setCaseStatusFinal(allowedStatuses)
    }
  }, [caseDetailsSlice?.caseAdminPanel?.statusAccess, roles])

  useEffect(() => {
    if (caseDetailsSlice.caseDetails) {
      const { timeline, reports, assignedRoles, assignedWorkflow, actualStep, checklistInstanceIds, status } =
        caseDetailsSlice.caseDetails

      setData(caseDetailsSlice.caseDetails)
      setTimeline(timeline ? [...timeline]?.reverse() : [])

      // CASE CLOSED
      if (status === 'CLOSED_WITH_ACTIONS' || status === 'CLOSED_WITHOUT_ACTIONS') {
        setCaseIsClosed(true)
      } else {
        setCaseIsClosed(false)
      }

      if (reports && reports.length > 0) {
        // REPORTS
        const filteredReports = reports?.filter((r) => !r.closed)
        const lastReport = filteredReports[0]

        setReports(reports)
        setLastReport(lastReport ?? '')
        setReport(lastReport ? lastReport?.description : '')
      } else {
        setReports([])
        setLastReport('')
        setReport('')
      }

      // ASSIGNED ROLES
      if (assignedRoles) {
        setAssignedRoles(assignedRoles)
        if (assignedRoles?.find((as) => roles.includes(as.roleName))) {
          setIsAssigned(true)
        } else {
          setIsAssigned(false)
        }
      } else {
        setIsAssigned(false)
      }

      // ASSIGNED WORKFLOW
      if (assignedWorkflow && actualStep !== undefined) {
        setAssignedWorkflow(assignedWorkflow)
        setActualStep(actualStep)

        const exists = Array.isArray(workflows?.workflows?.data)
          ? workflows.workflows.data.find((w) => w.id === assignedWorkflow)
          : undefined

        if (exists) {
          const isFinalStep = exists.steps?.length - 1 < actualStep + 1
          const isInitialStep = actualStep === 0

          setWorkflow(exists)
          setIsInitialStep(isInitialStep ? true : false)
          setIsFinalStep(isFinalStep ? true : false)
        }
      } else {
        setAssignedWorkflow('')
        setActualStep(undefined)
      }

      // CHECKLISTS
      if (checklistInstanceIds) {
        setChecklists(checklistInstanceIds)
      }
    }

    // CASE USER
    if (caseDetailsSlice.caseUser) {
      setUser(caseDetailsSlice.caseUser)
    }

    // CASE ADMIN PANEL
    if (
      Array.isArray(caseDetailsSlice?.caseAdminPanel?.defaultAssigned) &&
      caseDetailsSlice?.caseAdminPanel?.defaultAssigned.length > 0 &&
      caseDetailsSlice?.caseAdminPanel?.defaultAssigned.find((d) => roles.includes(d.roleName)) !== undefined
    ) {
      setHaspermissions(true)
    } else {
      setHaspermissions(false)
    }
  }, [caseDetailsSlice])

  useEffect(() => {
    if (user?.userStateDetails?.state && !process.env.REACT_APP_IS_DEMO) setUserState(user?.userStateDetails?.state)
  }, [user])

  // COMMENTS
  const postNewComment = () => {
    setIsLoading(true)
    setIsProcessing(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          description: newCommentMessage,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          setModalOpen(false)
          setNewCommentMessage('')
          updateDetail()
        })
    } else {
      setIsLoading(false)
      setModalOpen(false)
      setIsProcessing(false)
      setNewCommentMessage('')
    }
  }

  // REPORTS
  const createReport = () => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/reports`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          userId: data.userId,
          description: report,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:creationSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:createError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          updateDetail()
        })
        .catch((error) => {
          console.error('[CREATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:createError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const updateReport = () => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseReports/${lastReport._id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          userId: data.userId,
          description: report,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          setErrorAssign(false)
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
    }
  }

  const closeReport = () => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${lastReport._id}/reports/${id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          email,
          userId: data.userId,
          description: report,
          closed: true,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
    }
  }

  // CASE STATUS
  const updateCase = () => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlComment = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const optionsComment = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          description: caseToCloseComment !== '' ? caseToCloseComment : 'Closed without comment.',
          emailClient,
          email,
        }),
      }

      const optionsCase = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedRoles: assignedRoles.map((item) => item._id),
          ...(caseDetailsSlice.caseDetails.status !== status && status !== '' && { status }),
          ...(caseDetailsSlice.caseDetails.label !== label && label !== '' && { label }),
          ...(caseDetailsSlice.caseDetails.category !== category && category !== '' && { category }),
          reports,
        }),
      }

      fetch(url, optionsCase)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsLoading(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
              fetch(urlComment, optionsComment).catch((error) => {
                console.error('[COMMENT CASE ERROR] --> ', error)
              })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          updateDetail()
        })
        .catch((error) => {
          console.error('[CLOSE CASE ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })

      setOpenCloseCase(false)
      setCloseCaseModal(false)
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
      setOpenCloseCase(false)
      setCloseCaseModal(false)
    }
  }

  // USER STATUS
  const handleUserStateChange = () => {
    setIsEditingState(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      let body
      let url

      if (user?.type === 'consumer') {
        url = `${process.env.REACT_APP_BASEURL}/kyt/createConsumerUserEvent`
        body = {
          userId: data?.userId,
          updatedConsumerUserAttributes: {
            userStateDetails: {
              state: userState ? userState : '',
            },
          },
        }
      } else if (user?.type === 'business') {
        url = `${process.env.REACT_APP_BASEURL}/kyt/createBusinessUserEvent`
        body = {
          userId: data.userId,
          updatedBusinessUserAttributes: {
            userStateDetails: {
              state: userState ? userState : '',
            },
          },
        }
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setUserState(user?.userStateDetails?.state)
              setAux(true)
              setIsEditingState(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            }
          } else {
            setUserState(user?.userStateDetails?.state ?? '')
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          setIsEditingState(false)
          setOpenUserAction(false)
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR]', error)
          setIsEditingState(false)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setUserState('CREATED')
      setIsEditingState(false)
      setOpenUserAction(false)
    }
  }

  // ASSIGNED ROLES
  const updateAssigns = (assignedRoles2) => {
    setIsEditingAssign(true)
    setMessage('Updating case...')

    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ assignedRoles: assignedRoles2 }),
      }
      setMessage('Updating case...')
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsEditingAssign(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          setIsEditingAssign(false)
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE CASE ASSIGNED USERS ERROR]', error)
          setIsEditingAssign(false)
          toaster.error(
            error.message ? error.message : 'Update error! You probably do not have role enough to edit assig users.',
            {
              autoClose: 5000,
              closeButton: true,
            },
          )
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsEditingAssign(false)
    }
  }

  // CASE
  const updateDetail = () => {
    const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateCaseDetails(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[UPDATE CASE] --> ', error)
        dispatch(updateCaseDetails({ statusCode: 404 }))
        toaster.error(error.message ? error.message : t('messages:updateError'), {
          autoClose: 5000,
          closeButton: true,
        })
        setIsLoading(false)
      })
  }

  // FILES
  const uploadFile = (file) => {
    setIsProcessingFile(true)
    if (hasPermissions || isAssigned) {
      if (!process.env.REACT_APP_IS_DEMO) {
        const formData = new FormData()
        formData.append('file', file)

        let url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/file`

        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }

        fetch(url, options)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              toaster.success(t('messages:uploadFileSuccess'), { autoClose: 5000, closeButton: true })
            } else {
              toaster.error(
                res.data?.responseBody?.message
                  ? res.data?.responseBody?.message
                  : res.data?.info
                  ? res.data?.info
                  : res.message
                  ? res.message
                  : t('messages:createError'),
                {
                  autoClose: 5000,
                  closeButton: true,
                },
              )
            }
            setIsProcessingFile(false)
            updateDetail()
          })
          .catch((error) => {
            console.error('[UPLOAD CASE FILE ERROR] --> ', error)
            setIsProcessingFile(false)
            toaster.error(error.message ? error.message : t('messages:uploadFileError'), {
              autoClose: 5000,
              closeButton: true,
            })
          })
      } else {
        setIsProcessingFile(false)
        toaster.success(t('messages:uploadFileSuccess'), { autoClose: 5000, closeButton: true })
        updateDetail()
      }
    } else {
      setIsProcessingFile(false)
      toaster.error(t('messages:dontHavePermissionsAction'), {
        autoClose: 5000,
        closeButton: true,
      })
    }
  }

  const deleteFile = (e, file) => {
    e.preventDefault()
    setIsProcessingFile(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const splittedName = file.split('/case/')[1].split('?')
      const key = splittedName[splittedName.length - 2]

      let url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/file/${key}`

      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:deletionSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : t('messages:createError'),
              {
                autoClose: 5000,
                closeButton: true,
              },
            )
          }
          setIsProcessingFile(false)
          updateDetail()
        })
        .catch((error) => {
          console.error('[DELETE CASE FILE ERROR] --> ', error)
          setIsProcessingFile(false)
          toaster.error(error.message ? error.message : t('messages:deletionError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      setIsProcessingFile(false)
      toaster.success(t('messages:deletionSuccess'), { autoClose: 5000, closeButton: true })
      updateDetail()
    }
  }

  // STYLES
  const getStyles = (type) => {
    let color
    let backgroundColor

    switch (type.toLowerCase()) {
      case 'creation':
        backgroundColor = '#4184c3'
        color = '#fafafa'
        break
      case 'new_transaction':
        backgroundColor = '#0dde4f'
        color = '#494b5a'
        break
      case 'reopen':
        backgroundColor = '#bfb1f2'
        color = '#494b5a'
        break
      case 'report':
        backgroundColor = '#a3efa8'
        color = '#494b5a'
        break
      case 'file':
        backgroundColor = '#f2b1d9'
        color = '#494b5a'
        break
      case 'comment':
        backgroundColor = '#b18cd4'
        color = '#fafafa'
        break
      default:
        backgroundColor = '#fdb72b'
        color = '#494b5a'
        break
    }

    return { color, backgroundColor }
  }

  // WORKFLOWS
  const assignWorkflow = () => {
    if (workflow) {
      setIsLoading(true)
      setViewToast(true)
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`

      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedWorkflow: workflow.id,
          ...(workflow.steps[0].assignedRoles.length > 0 && {
            assignedRoles: workflow.steps[0].assignedRoles,
          }),
          ...(workflow.steps[0].caseStatus !== '' && {
            status: workflow.steps[0].caseStatus,
          }),
          ...(workflow.steps[0].label !== '' && { label: workflow.steps[0].label }),
          ...(workflow.steps[0].category !== '' && { category: workflow.steps[0].category }),
          actualStep: 0,
        }),
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          setWorkflowModal(false)
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE CASE] --> ', error)
          setMessage(error.message ? error.message : `${t('messages:updateError')}`)
          setErrorAssign(true)
          setWorkflowModal(false)
        })

      setTimeout(() => {
        setViewToast(false)
      }, 3000)
    }
  }

  const scaleCase = () => {
    if (workflow) {
      let scalePoint = isDownscale ? actualStep - 1 : actualStep + 1
      setIsLoading(true)
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedWorkflow: workflow.id,
          ...(workflow.steps[scalePoint].assignedRoles.length > 0 && {
            assignedRoles: workflow.steps[scalePoint].assignedRoles,
          }),
          ...(workflow.steps[scalePoint].caseStatus !== '' && {
            status: workflow.steps[scalePoint].caseStatus,
          }),
          ...(workflow.steps[scalePoint].label !== '' && { label: workflow.steps[scalePoint].label }),
          ...(workflow.steps[scalePoint].category !== '' && { category: workflow.steps[scalePoint].category }),
          actualStep: scalePoint,
        }),
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            setScaleModal(false)
            setIsDownscale(false)
            updateDetail()
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
        })
        .catch((error) => {
          console.error('[UPDATE CASE] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setScaleModal(false)
          setIsDownscale(false)
        })
    }
  }

  useEffect(() => {
    if (aux) {
      setAssignedRoles([])
      setAux(false)
    }
  }, [aux])

  const handleCloseUpdateCase = () => {
    setOpenCloseCase(false)
    setStatus('')
    setLabel('')
    setCategory('')
    setIsEditingAssign(false)
    setCaseToCloseComment('')
  }

  // OPEN CASE
  const reOpenCase = () => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlComment = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/openCase/${id}`
      const optionsComment = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          description: caseToOpenComment !== '' ? caseToOpenComment : 'Case re-opened without comment.',
          emailClient,
          email,
        }),
      }

      setOpenCaseModal(false)
      setCaseToOpenComment('')

      const optionsCase = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          status: 'REVIEW_IN_PROGRESS',
        }),
      }

      fetch(url, optionsCase)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsLoading(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
              fetch(urlComment, optionsComment).catch((error) => {
                console.error('[COMMENT CASE ERROR] --> ', error)
              })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          updateDetail()
        })
        .catch((error) => {
          console.error('[OPEN CASE ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
      setOpenCaseModal(false)
    }
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {viewToast && <AlertFetchSpinner message={message} error={errorAssign} />}
      {isLoading && <Spinner />}
      {user && (
        <Box className="kyb-detail">
          <Box className="kyb-detail-head">
            <Paper elevation={0} sx={{ p: '1rem', mr: '1rem' }}>
              <Box className="kyb-info">
                <Box className="kyb-info-section-head">
                  <Box className="kyb-info-section-1">
                    {/* NOMBRE */}
                    <Typography variant="h4">{t('common:caseDetail')}</Typography>
                    {/* CHIP */}
                    <Chip
                      label={data.label ?? t('common:notDefined')}
                      icon={<WorkOutlineOutlinedIcon />}
                      sx={{ backgroundColor: '#ffd7d4', color: '#ff8a00' }}
                    />
                  </Box>

                  {assignedWorkflow !== '' && workflow.steps?.length > 1 && !caseIsClosed && (
                    <Box className="kyb-info-section-1">
                      {!isInitialStep && (
                        <Button
                          onClick={() => {
                            setScaleModal(true)
                            setIsDownscale(true)
                          }}
                          variant="label"
                          startIcon={<UndoOutlinedIcon />}
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:downscale')}
                        </Button>
                      )}

                      {!isFinalStep && (
                        <Button
                          onClick={() => setScaleModal(true)}
                          variant="label"
                          endIcon={<RedoOutlinedIcon />}
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:scale')}
                        </Button>
                      )}

                      {isFinalStep && (
                        <Button
                          onClick={() => setCloseCaseModal(true)}
                          variant="action"
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:closeCase')}
                        </Button>
                      )}
                    </Box>
                  )}

                  {caseIsClosed && (
                    <Button
                      onClick={() => setOpenCaseModal(true)}
                      variant="action"
                      disabled={!hasPermissions && !isAssigned}
                    >
                      {t('common:reopenCase')}
                    </Button>
                  )}

                  {workflow.steps?.length === 1 && !caseIsClosed && (
                    <Button
                      onClick={() => setCloseCaseModal(true)}
                      variant="action"
                      disabled={!hasPermissions && !isAssigned}
                    >
                      {t('common:closeCase')}
                    </Button>
                  )}
                </Box>
                <Box className="kyb-info-section-4">
                  <Paper elevation={0} variant="colorPrimary">
                    {/* ID */}
                    <Typography variant="subtitle1">{t('common:caseId')}</Typography>
                    <Box className="copy-container">
                      <Typography variant="number">{data.maskId ? data.maskId : data._id}</Typography>

                      <Fab
                        size="small"
                        className="icon-global"
                        variant="action"
                        onClick={() => {
                          navigator.clipboard.writeText(data.maskId ? data.maskId : data._id)
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </Fab>
                    </Box>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER ID */}
                    <Typography variant="subtitle1">{t('common:userId')}</Typography>
                    <Box className="copy-container">
                      <Typography variant="number">
                        <Link href={`/users/${data.userId}?type=${user.type}`}>
                          <Typography variant="number">{data.userId}</Typography>
                        </Link>
                      </Typography>

                      <Fab
                        size="small"
                        className="icon-global"
                        variant="action"
                        onClick={() => {
                          navigator.clipboard.writeText(data.userId)
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </Fab>
                    </Box>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER NAME */}
                    <Typography variant="subtitle1">{t('common:userName')}</Typography>
                    <Typography variant="h5">
                      {renderNames(user?.userDetails?.name ? user?.userDetails?.name : user?.legalEntity)}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER TYPE */}
                    <Typography variant="subtitle1">{t('common:userType')}</Typography>
                    <Typography variant="h5">
                      {user?.type === 'consumer' ? 'Consumer User' : 'Business User'}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* DATE */}
                    <Typography variant="subtitle1">{t('common:date')}</Typography>
                    <Typography variant="h5" style={{ textWrap: 'nowrap' }}>
                      {data?.createdAt ? buildTimeStringFromTimestamp(data.createdAt) : '---'}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* STATUS */}
                    <Typography variant="subtitle1">{t('common:status')}</Typography>
                    <TableChip action={data.status} />
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* CATEGORY */}
                    <Typography variant="subtitle1">{t('common:category')}</Typography>
                    <Typography variant="h5">{data.category ?? '---'}</Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* LABEL */}
                    <Typography variant="subtitle1">{t('common:label')}</Typography>
                    <Typography variant="h5">{data.label ?? '---'}</Typography>
                  </Paper>
                </Box>
              </Box>
            </Paper>
            <Box className="details-sub2">
              {/* BOTÓN WORKFLOW */}
              {!caseIsClosed && (
                <Button
                  size="small"
                  onClick={() => setWorkflowModal(true)}
                  variant="contained"
                  disabled={
                    !activeServices.includes('workflows') ||
                    (activeServices.includes('workflows') && !isAssigned && !hasPermissions)
                  }
                >
                  {assignedWorkflow !== '' ? t('common:changeWorkflow') : t('common:assignWorkflow')}
                </Button>
              )}

              {/* BOTÓN EXPORT REPORT */}
              <Button
                variant="outlined"
                disabled={(!hasPermissions && !isAssigned) || !activeServices.includes('reportsitem')}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
              >
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>

              {/* BOTÓN BDC INFORMATION */}
              {data && data.userId && emailClient && emailClient === 'bancodecomercio@gueno.com' && (
                <Button
                  variant="outlinedGrey"
                  disabled={!hasPermissions && !isAssigned}
                  onClick={() => window.open(`${process.env.REACT_APP_REVEAL_INFORMATION_URL}${data.userId}`, '_blank')}
                >
                  {t('common:revealInformation')}
                </Button>
              )}

              {/* BOTÓN UPDATE CASE */}
              <Button
                size="small"
                onClick={() => setOpenCloseCase(true)}
                variant="outlinedGrey"
                disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
              >
                {t('common:updateCase')}
              </Button>

              <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
                {/* BOTÓN USER ACTION */}
                <Box className="risk-level-top">
                  <Typography variant="subtitle2">{t('common:userAction')}</Typography>
                  <Button
                    size="small"
                    onClick={() => setOpenUserAction(true)}
                    variant="outlined"
                    disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                  >
                    {t('common:edit')}
                  </Button>
                </Box>
                {userState ? <StatusIndicator type={userState} /> : <StatusIndicator type={'NOT_STARTED'} />}
              </Paper>
            </Box>
          </Box>

          <Paper elevation={0} sx={{ p: '1rem' }}>
            <Box className="kyb-info">
              <Typography variant="title">{t('common:report')}</Typography>
              <Box>
                <RichTextEditor
                  value={report}
                  onChange={setReport}
                  disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                />
              </Box>
              <Box className="reports-row">
                {lastReport && (
                  <Button
                    variant="outlinedGrey"
                    size="small"
                    disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                    onClick={() => {
                      closeReport()
                    }}
                  >
                    {t('common:close')} {t('common:report')}
                  </Button>
                )}
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={
                    (!hasPermissions && !isAssigned) ||
                    caseIsClosed ||
                    (!activeServices.includes('caseManagement:idreports') &&
                      !!activeServices.includes('caseReports:id'))
                  }
                  size="small"
                  onClick={() => {
                    !lastReport ? createReport() : updateReport()
                  }}
                >
                  {!lastReport ? `${t('common:create')}` : `${t('common:update')}`} {t('common:report')}
                </Button>
              </Box>
            </Box>
          </Paper>

          <Box className="kyb-subdetail">
            {/* TIMELINE */}
            <Box sx={{ paddingRight: '1rem' }}>
              <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
                <Typography variant="h4">{t('common:auditTrail')}</Typography>
                {Array.isArray(timeline) && (
                  <Box className="timeline-wrapper">
                    {timeline.length ? (
                      timeline.map((timeline) => {
                        const { color, backgroundColor } = getStyles(timeline.type)
                        return (
                          <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                            <Box className="timeline-box">
                              <Box title={t(`common:${timeline.type}`)}>
                                <KybChip
                                  action={t(`common:${timeline.type}`)}
                                  color={color}
                                  backgroundColor={backgroundColor}
                                />
                              </Box>
                            </Box>
                            <p>{buildTimeStringFromTimestamp(timeline.createdAt)}</p>

                            <Box className="timeline-box">
                              <p>{t('common:status')}:</p>
                              <Typography variant="text2">{t(`common:${timeline.status}`)}</Typography>
                            </Box>
                            <Box className="timeline-box">
                              <p>{t('common:operationalAgent')}:</p>
                              <Typography variant="text2">{timeline.email}</Typography>
                            </Box>
                            <Box className="timeline-box">
                              <p>{t('common:description')}:</p>
                              <Typography variant="text2">{timeline.description}</Typography>
                            </Box>
                          </Paper>
                        )
                      })
                    ) : (
                      <Paper className="timeline-card" elevation={0} variant="colorSecondary">
                        <Box className="timeline-box">
                          <Box title={timeline.type}>
                            <KybChip action={t(`common:NO_TIMELINE`)} color={'#494b5a'} backgroundColor={'#fdb72b'} />
                          </Box>
                        </Box>
                        <p>{'---'}</p>

                        <Box className="timeline-box">
                          <Box className="timeline-box">
                            <p>{t('common:status')}:</p>
                            <Typography variant="text2">---</Typography>
                          </Box>
                          <Box className="timeline-box">
                            <p>{t('common:operationalAgent')}:</p>
                            <Typography variant="text2">---</Typography>
                          </Box>
                          <Box className="timeline-box">
                            <p>{t('common:description')}:</p>
                            <Typography variant="text2">{t('common:noTimeline')}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    )}
                  </Box>
                )}
              </Paper>
            </Box>

            <Box className="kyb-subgrid">
              <TabContext value={selectedTab}>
                <CustomTabList
                  TabIndicatorProps={{
                    style: { display: 'none' },
                  }}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                >
                  <Tab
                    label={
                      <Chip
                        label={t('common:cases')}
                        variant={selectedTab === 'cases' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="cases"
                  ></Tab>

                  {activeServices.includes('generalAlerts') && (
                    <Tab
                      label={
                        <Chip
                          label={t('common:transactionAlerts')}
                          variant={selectedTab === 'transactionAlerts' ? 'sliderBlack' : 'sliderWhite'}
                        />
                      }
                      value="transactionAlerts"
                    />
                  )}

                  {activeServices.includes('generalAlerts') &&
                    data.generalAlerts?.filter((item) => item.type === 'amlCrypto').length > 0 && (
                      <Tab
                        label={
                          <Chip
                            label={t('common:amlCryptoAlerts')}
                            variant={selectedTab === 'amlCryptoAlerts' ? 'sliderBlack' : 'sliderWhite'}
                          />
                        }
                        value="amlCryptoAlerts"
                      />
                    )}

                  {activeServices.includes('generalAlerts') &&
                    data.generalAlerts?.filter((item) => item.type === 'aml').length > 0 && (
                      <Tab
                        label={
                          <Chip
                            label={t('common:amlAlerts')}
                            variant={selectedTab === 'amlAlerts' ? 'sliderBlack' : 'sliderWhite'}
                          />
                        }
                        value="amlAlerts"
                      />
                    )}

                  <Tab
                    label={
                      <Chip
                        label={t('common:transactions')}
                        variant={selectedTab === 'transactions' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="transactions"
                  />

                  <Tab
                    label={
                      <Chip
                        label={t('common:assignedRoles')}
                        variant={selectedTab === 'assignedRoles' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="assignedRoles"
                  />

                  <Tab
                    label={
                      <Chip
                        label={t('common:checklists')}
                        variant={selectedTab === 'checklists' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="checklists"
                  />

                  <Tab
                    label={
                      <Chip
                        label={t('common:reports')}
                        variant={selectedTab === 'reports' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="reports"
                  />

                  <Tab
                    label={
                      <Chip
                        label={t('common:comments')}
                        variant={selectedTab === 'comments' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="comments"
                  />

                  <Tab
                    label={
                      <Chip
                        label={t('common:documents')}
                        variant={selectedTab === 'documents' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="documents"
                  />
                </CustomTabList>

                <Box>
                  <Paper elevation={0}>
                    <TabPanel value="cases">
                      <Box className="kyb-detail-documents">
                        <Box className="kyb-documents-top">
                          <Typography variant="title">{t('common:cases')}</Typography>
                        </Box>

                        <DatatableUserCases userId={data.userId} isFromDetail={true} hasPermissions={hasPermissions} />
                      </Box>
                    </TabPanel>

                    {activeServices.includes('generalAlerts') && (
                      <TabPanel value="transactionAlerts">
                        <Box className="kyb-detail-documents">
                          <Box className="kyb-documents-top">
                            <Typography variant="title">{t('common:transactionsAlerts')}</Typography>
                          </Box>

                          <GeneralAlertsDatatable
                            type={'transactionRule'}
                            hideActions={true}
                            hasPermissions={hasPermissions}
                            rows={data.generalAlerts
                              ?.filter((item) => item.type === 'transactionRule')
                              .map((item) => {
                                return { ...item, id: item._id }
                              })}
                            isFromDetail={true}
                          />
                        </Box>
                      </TabPanel>
                    )}

                    {activeServices.includes('generalAlerts') &&
                      data.generalAlerts?.filter((item) => item.type === 'amlCrypto').length > 0 && (
                        <TabPanel value="amlCryptoAlerts">
                          <Box className="kyb-detail-documents">
                            <Box className="kyb-documents-top">
                              <Typography variant="title">{t('common:amlCryptoAlerts')}</Typography>
                            </Box>

                            <GeneralAlertsDatatable
                              type={'amlCrypto'}
                              hideActions={true}
                              rows={data.generalAlerts
                                ?.filter((item) => item.type === 'amlCrypto')
                                .map((item) => {
                                  return { ...item, id: item._id }
                                })}
                              hasPermissions={hasPermissions}
                              isFromDetail={true}
                            />
                          </Box>
                        </TabPanel>
                      )}

                    {activeServices.includes('generalAlerts') &&
                      data.generalAlerts?.filter((item) => item.type === 'aml').length > 0 && (
                        <TabPanel value="amlAlerts">
                          <Box className="kyb-detail-documents">
                            <Box className="kyb-documents-top">
                              <Typography variant="title">{t('common:amlAlerts')}</Typography>
                            </Box>

                            <GeneralAlertsDatatable
                              type={'aml'}
                              hideActions={true}
                              rows={data.generalAlerts
                                ?.filter((item) => item.type === 'aml')
                                .map((item) => {
                                  return { ...item, id: item._id }
                                })}
                              hasPermissions={hasPermissions}
                              isFromDetail={true}
                            />
                          </Box>
                        </TabPanel>
                      )}

                    <TabPanel value="transactions">
                      <Box className="kyb-detail-documents">
                        <Box className="kyb-documents-top">
                          <Typography variant="title">{t('common:transactions')}</Typography>
                        </Box>

                        <DatatableCaseTransaction rows={data.transactions} />
                      </Box>
                    </TabPanel>

                    <TabPanel value="assignedRoles">
                      <Box className="kyb-card">
                        <Box className="card-top">
                          <Typography variant="title">{t('common:assignedRoles')}</Typography>
                          <Box>
                            {!isEditingAssign ? (
                              <Button
                                onClick={() => {
                                  setIsEditingAssign(true)
                                }}
                                size="small"
                                variant="contained"
                                disabled={!hasPermissions || caseIsClosed}
                              >
                                {t('common:addRole')}
                              </Button>
                            ) : (
                              <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                                <CloseOutlinedIcon />
                              </Fab>
                            )}
                          </Box>
                        </Box>
                        <DatatableAssignRoles
                          rows={assignedRoles}
                          toAssign={rolesState?.data}
                          isEditing={isEditingAssign}
                          updateAssigns={updateAssigns}
                          setIsEditingAssign={setIsEditingAssign}
                          errorAssign={errorAssign}
                        />
                      </Box>
                    </TabPanel>

                    <TabPanel value="checklists">
                      <Box className="kyb-detail-documents">
                        <Box className="kyb-documents-top">
                          <Typography variant="title">{t('common:checklists')}</Typography>
                        </Box>

                        <DatatableChecklistInstances
                          checklists={checklists}
                          setChecklists={setChecklists}
                          isLoading={isLoading}
                          hasPermissions={hasPermissions}
                        />
                      </Box>
                    </TabPanel>

                    <TabPanel value="documents">
                      <Box className="kyb-detail-documents">
                        <Box className="kyb-documents-top">
                          <Typography variant="title">{t('common:documents')}</Typography>
                          <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                        </Box>

                        <Box className="kyb-files-drag">
                          <Typography variant="title2">{t('common:addAFile')}</Typography>
                          <FileUploader
                            handleChange={(file) => uploadFile(file)}
                            name="file"
                            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                            label={t('common:fileUploaderLabel')}
                            types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'EML', 'MP4', 'MP3', 'MPEG', 'WEBM', 'WAV']}
                            children={
                              <Paper elevation={0} variant="rootDashedTransparent" className="draggable-main">
                                <Box
                                  className={
                                    !hasPermissions && !isAssigned ? 'draggable-icon-disabled' : 'draggable-icon'
                                  }
                                ></Box>
                                <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                                <Box>
                                  <Typography variant="subtitle4">{t('common:or')} </Typography>
                                  <Typography
                                    sx={{ textDecoration: 'underline' }}
                                    variant="subtitle4"
                                    color={!hasPermissions && !isAssigned ? '#66766b' : '#00c43d'}
                                  >
                                    {t('common:browseMedia')}
                                  </Typography>
                                </Box>
                              </Paper>
                            }
                          />
                          <Typography variant="subtitle4">
                            {t('common:supportedMedia')}: JPG, PNG, PDF, EML, GIF, MP4, MP3, MPEG, WEBM, WAV.
                          </Typography>
                        </Box>

                        <Box className="kyb-file-list-wrapper">
                          <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                          {processingError !== '' && (
                            <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                              {processingError}
                            </Typography>
                          )}
                          <Box className="kyb-file-list">
                            {isProcessingFile ? (
                              <ProcessingSpinner message={t('common:processing')} />
                            ) : (
                              <Box>
                                {Array.isArray(data?.files) && data.files.length > 0 && (
                                  <Box className="kyb-files">
                                    {data.files.map((file, index) => {
                                      const splittedName = file.split('/case/')[1].split('_')
                                      const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                      return (
                                        <Paper elevation={0} variant="colorPrimary" className="kyb-file" key={index}>
                                          <Box className="kyb-file-description">
                                            <Box className="color-button">
                                              <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                                FILE
                                              </Typography>
                                            </Box>

                                            <Typography variant="text2" title={fileName}>
                                              {fileName}
                                            </Typography>
                                          </Box>
                                          <Box className="kyb-file-icons">
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              onClick={() => window.open(file, '_blank')}
                                            >
                                              {t('common:view')}
                                            </Button>

                                            <Button
                                              variant="outlinedBlank"
                                              disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                                              onClick={(e) => deleteFile(e, file)}
                                            >
                                              <DeleteOutlineIcon />
                                            </Button>
                                          </Box>
                                        </Paper>
                                      )
                                    })}
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </TabPanel>

                    <TabPanel value="comments">
                      <Box className="kyb-card">
                        <Box className="card-top">
                          <Typography variant="title">{t('common:comments')}</Typography>
                          {/* BOTÓN COMENTARIOS */}
                          <Button
                            size="small"
                            onClick={() => setModalOpen(true)}
                            variant="contained"
                            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                          >
                            {t('common:postComment')}
                          </Button>
                        </Box>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('common:email')}</TableCell>
                                <TableCell>{t('common:createdAt')}</TableCell>
                                <TableCell>{t('common:comment')}</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {Array.isArray(data?.comments) && data?.comments?.length > 0 ? (
                                data.comments.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Box>{row.email ?? '---'}</Box>
                                      </TableCell>
                                      <TableCell>
                                        {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                      </TableCell>
                                      <TableCell>{row.description ?? '---'}</TableCell>
                                    </TableRow>
                                  )
                                })
                              ) : (
                                <Box className="table-custom-overlay">
                                  <CustomNoRowsOverlay />
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>

                    <TabPanel value="reports">
                      <Box className="kyb-card">
                        <Box className="card-top">
                          <Typography variant="title">{t('common:reports')}</Typography>
                        </Box>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('common:user')}</TableCell>
                                <TableCell>{t('common:status')}</TableCell>
                                <TableCell>{t('common:createdAt')}</TableCell>
                                <TableCell>{t('common:updatedAt')}</TableCell>
                                <TableCell>{t('common:actions')}</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {Array.isArray(data?.reports) && data?.reports?.length > 0 ? (
                                data.reports.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{row.email}</TableCell>
                                      <TableCell>
                                        <TableChip noIcon={true} action={row.closed ? 'CLOSED' : 'OPEN'} />
                                      </TableCell>
                                      <TableCell>
                                        {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                      </TableCell>
                                      <TableCell>
                                        {row.updatedAt ? buildTimeStringFromTimestamp(row.updatedAt) : '---'}
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          onClick={() => {
                                            setSelectedReport(row)
                                            return setOpenModalReport(true)
                                          }}
                                        >
                                          {t('common:viewDetails')}
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                              ) : (
                                <Box className="table-custom-overlay">
                                  <CustomNoRowsOverlay />
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>
                  </Paper>
                </Box>
              </TabContext>
            </Box>
          </Box>
        </Box>
      )}

      {/* DATA EXPORT MODAL */}
      <CaseToPdf show={showDownloadModal} setShow={setShowDownloadModal} caseDetail={data} userDetail={user} />

      {/* MODAL CREAR COMMENT */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        {isProcessing ? (
          <ProcessingSpinner message={t('common:processing')} />
        ) : (
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('common:postComment')}</Typography>
                <Typography variant="subtitle3">{t('messages:commentDesc')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => setModalOpen(false)}>
                <CloseOutlinedIcon />
              </Fab>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:comment')}:</InputLabel>
              <TextField
                multiline
                placeholder={t('messages:minCharacters')}
                required
                onChange={(e) => setNewCommentMessage(e.target.value)}
              />
            </Box>
            <Box className="modal-button">
              <Button
                size="small"
                color="secondary"
                variant="contained"
                disabled={newCommentMessage.length < 5}
                onClick={postNewComment}
              >
                {t('common:publishComment')}
              </Button>
            </Box>
          </Box>
        )}
      </Modal>

      {/* MODAL WORKFLOW */}
      <Modal open={workflowModal} onClose={() => setWorkflowModal(false)}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:assignWorkflow')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setWorkflowModal(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="case-workflow-info">
            <Typography variant="subtitle3">{t('messages:assignWorkflowInfo')}</Typography>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:select')}:</InputLabel>
            <Select fullWidth size="small" value={workflow} onChange={(e) => setWorkflow(e.target.value)} displayEmpty>
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:workflow')}
                </Typography>
              </MenuItem>
              {Array.isArray(workflows?.workflows?.data) &&
                workflows?.workflows?.data?.length > 0 &&
                workflows?.workflows?.data?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setWorkflowModal(false)}>
              {t('common:close')}
            </Button>
            <Button disabled={workflow === ''} variant="contained" onClick={assignWorkflow}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL SCALE */}
      <Modal open={scaleModal} onClose={() => setScaleModal(false)}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:scale')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setScaleModal(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="case-workflow-info">
            <Typography variant="subtitle3">{t('messages:scaleCaseInfo')}</Typography>
            <Typography variant="subtitle2">
              {t('common:currentStep')}: {actualStep + 1}
            </Typography>
          </Box>

          <Divider />

          <Box className="modal-box">
            <InputLabel>{t('common:comment')}:</InputLabel>
            <TextField
              multiline
              placeholder={t('messages:minCharacters')}
              required
              onChange={(e) => setNewCommentMessage(e.target.value)}
            />
            <Box className="case-workflow-info">
              <Typography variant="subtitle3">{t('messages:commentDesc')}</Typography>
            </Box>
          </Box>

          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setScaleModal(false)}>
              {t('common:close')}
            </Button>
            <Button
              variant="contained"
              disabled={newCommentMessage.length < 5}
              onClick={() => {
                postNewComment()
                return scaleCase()
              }}
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL CLOSE CASE */}
      <Modal
        open={closeCaseModal}
        onClose={() => {
          setCloseCaseModal(false)
          setStatus('CLOSED_WITHOUT_ACTIONS')
          setCaseToCloseComment('')
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:closeCase')}</Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                setCloseCaseModal(false)
                setStatus('CLOSED_WITHOUT_ACTIONS')
                setCaseToCloseComment('')
              }}
            >
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:newStatus')}:</InputLabel>
            <Select fullWidth size="small" value={status} onChange={(e) => setStatus(e.target.value)} displayEmpty>
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:newStatus')}
                </Typography>
              </MenuItem>
              {['CLOSED_WITH_ACTIONS', 'CLOSED_WITHOUT_ACTIONS']?.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Divider />

          <Box className="modal-box">
            <InputLabel>{t('common:updatingReasons')}:</InputLabel>
            <TextField
              multiline
              rows={4}
              size="small"
              value={caseToCloseComment}
              onChange={(event) => setCaseToCloseComment(event.target.value)}
              placeholder={t('messages:minCharacters')}
            />
          </Box>

          <Box className="modal-filter-buttons">
            <Button
              variant="outlinedGrey"
              onClick={() => {
                setCloseCaseModal(false)
                setStatus('CLOSED_WITHOUT_ACTIONS')
                setCaseToCloseComment('')
              }}
            >
              {t('common:close')}
            </Button>
            <Button variant="contained" disabled={caseToCloseComment.length < 5} onClick={updateCase}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL OPEN CASE */}
      <Modal
        open={openCaseModal}
        onClose={() => {
          setOpenCaseModal(false)
          setCaseToOpenComment('')
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:reopenCase')}</Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                setOpenCaseModal(false)
                setCaseToOpenComment('')
              }}
            >
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:openingReasons')}:</InputLabel>
            <TextField
              multiline
              rows={4}
              size="small"
              value={caseToOpenComment}
              onChange={(event) => setCaseToOpenComment(event.target.value)}
              placeholder={t('messages:minCharacters')}
            />
          </Box>

          <Box className="modal-filter-buttons">
            <Button
              variant="outlinedGrey"
              onClick={() => {
                setOpenCaseModal(false)
                setCaseToOpenComment('')
              }}
            >
              {t('common:close')}
            </Button>
            <Button variant="contained" disabled={caseToOpenComment.length < 5} onClick={reOpenCase}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL USER STATUS */}
      <Modal
        open={openUserAction}
        onClose={() => {
          setOpenUserAction(false)
          setUserState(user?.userStateDetails?.state ?? '')
        }}
      >
        {isEditingState ? (
          <ProcessingSpinner message={t('common:processing')} />
        ) : (
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">
                  {t('common:update')} {t('common:status')}
                </Typography>
              </Box>
              <Fab
                variant="close"
                onClick={() => {
                  setOpenUserAction(false)
                  setUserState(user?.userStateDetails?.state ?? '')
                }}
              >
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="modal-box-filters">
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('messages:doYouWantUpdateUserStatus')}?</Typography>
              </Box>
            </Box>

            <Box className="modal-box">
              <InputLabel>{t('common:newStatus')}:</InputLabel>
              <Select
                fullWidth
                size="small"
                value={userState}
                onChange={(e) => {
                  if (e.target.value) {
                    setUserState(e.target.value)
                  } else setUserState('')
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newStatus')}
                  </Typography>
                </MenuItem>
                {userStateValues?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-filter-buttons">
              <Button
                variant="outlinedGrey"
                onClick={() => {
                  setOpenUserAction(false)
                  setUserState(user?.userStateDetails?.state ?? '')
                }}
              >
                {t('common:close')}
              </Button>
              <Button disabled={userState === ''} variant="contained" onClick={handleUserStateChange}>
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        )}
      </Modal>

      {/* MODAL UPDATE CASE */}
      <Modal open={openCloseCase} onClose={handleCloseUpdateCase}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:updateCase')}</Typography>
            </Box>
            <Fab variant="close" onClick={handleCloseUpdateCase}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('messages:doYouWantUpdate')} {id}?
              </Typography>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newStatus')}:</InputLabel>
              <Select fullWidth size="small" value={status} onChange={(e) => setStatus(e.target.value)} displayEmpty>
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newStatus')}
                  </Typography>
                </MenuItem>
                {caseStatusFinal?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(`common:${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newLabel')}:</InputLabel>
              <Select fullWidth size="small" value={label} onChange={(e) => setLabel(e.target.value)} displayEmpty>
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newLabel')}
                  </Typography>
                </MenuItem>
                {caseDetailsSlice?.caseAdminPanel?.labels?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newCategory')}:</InputLabel>
              <Select
                fullWidth
                size="small"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newCategory')}
                  </Typography>
                </MenuItem>
                {caseDetailsSlice?.caseAdminPanel?.categories?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <Box className="modal-top">
                <InputLabel>{t('common:assignedRoles')}</InputLabel>
                {activeServices.includes('caseManagement:id') && (
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => {
                        setIsEditingAssign(!isEditingAssign)
                      }}
                      variant={!isEditingAssign ? 'outlinedButtonSuccess' : 'outlinedButtonError'}
                    >
                      {!isEditingAssign && <EditIcon />}
                      {isEditingAssign && <CloseIcon />}
                    </Button>
                  </Box>
                )}
              </Box>
              <DatatableAssignRoles
                rows={assignedRoles}
                toAssign={rolesState?.data}
                isEditing={isEditingAssign}
                updateAssigns={updateAssigns}
                setIsEditingAssign={setIsEditingAssign}
                errorAssign={errorAssign}
              />
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:updatingReasons')}:</InputLabel>
              <TextField
                multiline
                rows={4}
                size="small"
                value={caseToCloseComment}
                onChange={(event) => setCaseToCloseComment(event.target.value)}
                placeholder={t('messages:closingReason')}
              />
            </Box>
            <Box className="modal-filter-buttons">
              <Button variant="outlinedGrey" onClick={handleCloseUpdateCase}>
                {t('common:close')}
              </Button>
              <Button disabled={!caseToCloseComment} variant="contained" onClick={updateCase}>
                {t('common:update')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* MODAL REPORT */}
      <Modal
        open={modalReport}
        onClose={() => {
          setSelectedReport('')
          return setOpenModalReport(false)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">
                {t('common:report')}{' '}
                {selectedReport.updatedAt ? buildTimeStringFromTimestamp(selectedReport.updatedAt) : '---'}
              </Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                setSelectedReport('')
                return setOpenModalReport(false)
              }}
            >
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box dangerouslySetInnerHTML={{ __html: selectedReport.description }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Case
