import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { convertToUTCDate, formatDate } from '../../components/common/time/timeHelper'

const buildTransactionsParams = (query = {}) => {
  const {
    fromDate,
    toDate,
    ruleId,
    transactionId,
    transactionType,
    transactionState,
    originUserId,
    destinationUserId,
    originCurrency,
    destinationCurrency,
    originMethod,
    destinationMethod,
    ruleInstanceId,
    shadowRuleInstanceId,
    maxRuleAction,
    transactionKey,
    transactionValue,
    documentNumber,
    firstName,
    lastName,
    legalName,
    timezone = -3,
  } = query

  const params = {
    ...(ruleId?.length && { ruleId }),
    ...(transactionId && { transactionId }),
    ...(transactionType && { transactionType }),
    ...(transactionState && { transactionState }),
    ...(originUserId && { originUserId }),
    ...(destinationUserId && { destinationUserId }),
    ...(originCurrency !== null && { originCurrency: originCurrency.aka }),
    ...(destinationCurrency !== null && { destinationCurrency: destinationCurrency.aka }),
    ...(originMethod !== null && { originMethod }),
    ...(destinationMethod !== null && { destinationMethod }),
    ...(ruleInstanceId && { ruleInstanceId }),
    ...(shadowRuleInstanceId && { shadowRuleInstanceId }),
    ...(maxRuleAction && { maxRuleAction }),
    ...(transactionKey && { key: transactionKey }),
    ...(transactionValue && { value: transactionValue }),
    ...(documentNumber && { documentNumber }),
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(legalName && { legalName }),
  }

  if (fromDate && toDate) {
    params.fromDate = formatDate(convertToUTCDate(fromDate, timezone))
    params.toDate = formatDate(convertToUTCDate(toDate, timezone))
  }

  return params
}

const buildUsersParams = (query = {}) => {
  const {
    fromDate,
    toDate,
    userId,
    firstName,
    middleName,
    countryOfResidence,
    countryOfNationality,
    legalName,
    countryOfRegistration,
    userKey,
    userValue,
    selectedUserState,
    userKycStatus,
    documentNumber,
  } = query

  const params = {
    ...(userId?.length && { userId }),
    ...(firstName?.length && { firstName }),
    ...(middleName?.length && { middleName }),
    ...(countryOfResidence?.length && { countryOfResidence }),
    ...(countryOfNationality?.length && { countryOfNationality }),
    ...(legalName?.length && { legalName }),
    ...(countryOfRegistration?.length && { countryOfRegistration }),
    ...(userKey && { key: userKey }),
    ...(userValue && { value: userValue }),
    ...(selectedUserState && { userState: selectedUserState }),
    ...(userKycStatus && { userKycStatus }),
    ...(documentNumber && { documentNumber }),
  }

  if (fromDate && toDate) {
    params.fromDate = fromDate
    params.toDate = toDate
  }

  return params
}

export const defaultKytTransactionFilters = {
  viewMode: true,
  page: 0,
  limit: 10,
  offset: 0,
  transactionId: '',
  transactionType: null,
  transactionState: null,
  originUserId: '',
  destinationUserId: '',
  fromDate: null,
  toDate: null,
  originCurrency: null,
  destinationCurrency: null,
  originMethod: null,
  destinationMethod: null,
  ruleId: '',
  ruleInstanceId: '',
  shadowRuleInstanceId: '',
  maxRuleAction: null,
  documentNumber: '',
  transactionValue: null,
  legalName: '',
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactions: [],
    transactionDetails: {},
    transactionDetailsOrigin: {},
    transactionDetailsDestination: {},
    annualTransactions: [],
    monthTransactions: [],
    filters: {
      ...defaultKytTransactionFilters,
    },
  },
  reducers: {
    updateTransactionDetails: (state, details) => {
      state.transactionDetails = details.payload
    },
    updateTransactionDetailsOrigin: (state, details) => {
      state.transactionDetailsOrigin = details.payload
    },
    updateTransactionDetailsDestination: (state, details) => {
      state.transactionDetailsDestination = details.payload
    },
    updateAnnualTransactions: (state, annualTransactions) => {
      state.annualTransactions = annualTransactions.payload
    },
    updateMonthTransactions: (state, monthTransactions) => {
      state.monthTransactions = monthTransactions.payload
    },
    performLogoutTransactions: (state) => {
      state.transactions = []
      state.annualTransactions = []
      state.monthTransactions = []
      state.transactionDetails = {}
      state.transactionDetailsOrigin = {}
      state.transactionDetailsDestination = {}
    },
    updateTransactionsFilters: (state, filters) => {
      state.filters = filters.payload
    },
    resetTransactionsFilters: (state) => {
      state.filters = { ...defaultKytTransactionFilters }
    },
  },
})

const transactionsApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findTransactions: builder.query({
      query: ({ emailClient, viewMode, limit, offset, page, sortField, sortDirection, ...query }) => {
        const searchParams = buildTransactionsParams(query)

        return {
          url: `/kyt/transactions/${emailClient}`,
          params: {
            viewMode,
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
    findTransactionsByUserId: builder.query({
      query: ({ emailClient, viewMode, limit, offset, page, userId }) => {
        return {
          url: `/kyt/transactions/${emailClient}`,
          params: {
            viewMode,
            limit,
            offset,
            page,
            transactionUserId: userId,
          },
        }
      },
    }),
    findTransactionsTags: builder.query({
      query: ({ emailClient }) => {
        return {
          url: `/kyt/transactions/${emailClient}/tags`,
        }
      },
      keepUnusedDataFor: 60,
    }),
    findKytUsers: builder.query({
      query: ({ emailClient, type, viewMode, limit, offset, page, ...query }) => {
        const searchParams = buildUsersParams(query)

        return {
          url: `/kyt/${type}/${emailClient}`,
          params: {
            viewMode,
            limit,
            offset,
            page,
            ...searchParams,
          },
        }
      },
      providesTags: (_, __, args) => [`kyt-${args.type}`],
    }),
    createConsumerUserEvent: builder.mutation({
      query: ({ id, state }) => {
        return {
          url: `/kyt/createConsumerUserEvent`,
          method: 'POST',
          body: {
            userId: id,
            updatedConsumerUserAttributes: {
              userStateDetails: {
                state,
              },
            },
          },
        }
      },
      invalidatesTags: ['kyt-consumerUsers'],
    }),
    createBusinessUserEvent: builder.mutation({
      query: ({ id, state }) => {
        return {
          url: `/kyt/createBusinessUserEvent`,
          method: 'POST',
          body: {
            userId: id,
            updatedBusinessUserAttributes: {
              userStateDetails: {
                state,
              },
            },
          },
        }
      },
      invalidatesTags: ['kyt-businessUsers'],
    }),
  }),
})

export const {
  updateTransactionDetails,
  updateTransactionDetailsOrigin,
  updateTransactionDetailsDestination,
  updateAnnualTransactions,
  updateMonthTransactions,
  performLogoutTransactions,
  updateTransactionsFilters,
  resetTransactionsFilters,
} = transactionSlice.actions

export const {
  useFindTransactionsQuery,
  useFindTransactionsTagsQuery,
  useFindTransactionsByUserIdQuery,
  useLazyFindTransactionsTagsQuery,
  useFindKytUsersQuery,
  useCreateConsumerUserEventMutation,
  useCreateBusinessUserEventMutation,
} = transactionsApi

export default transactionSlice.reducer
