import { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Switch, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFindUserMasterQuery, useUpdateUserMasterMutation } from '../../../services/guenoApi'
import KybListOfStrings from './KybListOfStrings'
import KybChecksDefaultOrder from './KybChecksDefaultOrder.json'
import toaster from '../../../toaster'
import { LoadingButton } from '@mui/lab'
import KybModal from './KybModal'

const renderTooltip = (title) => (
  <Tooltip title={title}>
    <Button>
      <InfoIcon className="security-info-icon" />
    </Button>
  </Tooltip>
)

const KybSettingsTabPanel = ({ currentCountry }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const { data: kybSettings, refetch } = useFindUserMasterQuery({ emailClient })
  const [updateUserMaster, { isLoading: isLoadingUpdateUserMaster }] = useUpdateUserMasterMutation({
    fixedCacheKey: 'userMasterUpdate',
  })
  const [currentBussinesChecks, setCurrentBussinesChecks] = useState([])
  const [currentPersonChecks, setCurrentPersonChecks] = useState([])
  const [currentRiskMatrixSkipNoDataFound, setCurrentRiskMatrixSkipNoDataFound] = useState(false)
  const [openModalRiskMatrix, setOpenModalRiskMatrix] = useState(false)

  const setKybData = async (field, value) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          [field]: value,
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(t('messages:updateError'))
    }
  }

  const setKybChecks = async (subject, kybChecks) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          kybChecks: { [currentCountry]: { [subject]: kybChecks } },
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(t('messages:updateError'))
    }
  }

  const setRiskMatrixIgnoreNoData = async () => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: { riskMatrixSkipNoDataFound: !currentRiskMatrixSkipNoDataFound },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
        setOpenModalRiskMatrix(false)
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(t('messages:updateError'))
    }
  }

  useEffect(() => {
    if (kybSettings?.kybChecks) {
      setCurrentBussinesChecks(kybSettings.kybChecks[currentCountry]?.BUSINESS || [])
      setCurrentPersonChecks(kybSettings.kybChecks[currentCountry]?.PERSON || [])
    } else {
      setCurrentBussinesChecks(KybChecksDefaultOrder[currentCountry]?.BUSINESS || [])
      setCurrentPersonChecks(KybChecksDefaultOrder[currentCountry]?.PERSON || [])
    }
    setCurrentRiskMatrixSkipNoDataFound(kybSettings.riskMatrixSkipNoDataFound ?? false)
  }, [kybSettings, currentCountry])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:riskMatrix')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" alignItems="center">
              <p>{t('messages:kyb.settings.riskMatrixSkipNoDataFound')}</p>
              <Box className="security-flex-label">
                {renderTooltip(
                  <Box className="back-tip">
                    <label className="security-items-info" color="inherit">
                      {t('messages:kyb.settings.riskMatrixSkipNoDataFound.tooltip')}
                    </label>
                  </Box>,
                )}
                <Switch
                  color="secondary"
                  name="checkPasswordHistoryLength"
                  id="checkPasswordHistoryLength"
                  onChange={() => setOpenModalRiskMatrix(true)}
                  checked={currentRiskMatrixSkipNoDataFound}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:businessSegments')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={kybSettings?.businessSegments}
              setStringsList={(value) => setKybData('businessSegments', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:clientTypes')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={kybSettings?.clientTypes}
              setStringsList={(value) => setKybData('clientTypes', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:clusters')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={kybSettings?.clusters}
              setStringsList={(value) => setKybData('clusters', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px', '&:before': { display: 'none' } }}>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography variant="h3">{t('common:checks')}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
            }}
          >
            <Box width="100%">
              <KybListOfStrings
                title="business"
                stringsList={currentBussinesChecks}
                setStringsList={(newChecks) => setKybChecks('BUSINESS', newChecks)}
                isLoading={isLoadingUpdateUserMaster}
                translateStrings
                inputsDisabled
                noAddRemove
              />
            </Box>
            <Box width="100%">
              <KybListOfStrings
                title="person"
                stringsList={currentPersonChecks}
                setStringsList={(newChecks) => setKybChecks('PERSON', newChecks)}
                isLoading={isLoadingUpdateUserMaster}
                translateStrings
                inputsDisabled
                noAddRemove
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <KybModal
        title={`${t('common:edit')} ${t('common:riskMatrix')}`}
        open={openModalRiskMatrix}
        setOpen={setOpenModalRiskMatrix}
      >
        <Box className="modal-box">
          <p>{t('messages:kyb.settings.riskMatrixSkipNoDataFound.modal')}</p>
        </Box>
        <Box className="modal-button">
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => setRiskMatrixIgnoreNoData()}
            loading={isLoadingUpdateUserMaster}
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
      </KybModal>
    </>
  )
}

export default KybSettingsTabPanel
