import { useState } from 'react'
import { IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  RangeNumberInputForm,
  SelectInputForm,
  SelectListInputForm,
  TagsInputForm,
  TextInputForm,
} from '../../../../components/common/form'
import { defaultValuesByType, inputTypeByOperator, kybAttributes, kybAttributesList } from '../constants'
import { useTranslation } from 'react-i18next'
import { kybStatusEnum } from '../../../kyb/kyb.enums'

const RiskMatrixParameter = ({ index, country, control, watch, remove, setValue, move, total }) => {
  const { t } = useTranslation(['common', 'messages'])

  const [{ attribute, operator }] = watch([`params.${index}`])
  const [operators, setOperators] = useState(attribute ? kybAttributes[country]?.[attribute]?.operators : [])

  const type = inputTypeByOperator?.[operator]

  const onAttributeChange = (e) => {
    const newAttribute = e.target.value
    const newOperators = kybAttributes[country]?.[newAttribute]?.operators || []
    const defaultOperator = newOperators?.length ? newOperators[0] : ''
    setOperators(newOperators)
    setValue(
      `params.${index}`,
      {
        attribute: newAttribute,
        operator: defaultOperator,
        value: defaultOperator ? defaultValuesByType[inputTypeByOperator[defaultOperator]] : '',
        score: 0,
      },
      {
        shouldValidate: false,
        shouldTouch: true,
        shouldDirty: true,
      },
    )
  }

  const onOperatorChange = (e) => {
    const newOperator = e.target.value

    setValue(`params.${index}`, {
      attribute,
      operator: newOperator,
      value: newOperator ? defaultValuesByType[inputTypeByOperator[newOperator]] : '',
      score: 0,
    })
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid xs={4}>
        <SelectInputForm
          name={`params.${index}.attribute`}
          control={control}
          defaultValue=""
          options={kybAttributesList(country).map((attr) => ({
            label: t(`messages:riskmatrix.attribute.${country}.${attr.key}`),
            value: attr.key,
          }))}
          emptyLabel={`${t('common:select')} ${t('common:attribute')}`}
          onchange={onAttributeChange}
          required
        />
      </Grid>
      <Grid xs={2}>
        <SelectInputForm
          name={`params.${index}.operator`}
          control={control}
          defaultValue={operators.length ? operators[0] : ''}
          options={operators.map((operator) => ({
            label: t(`messages:riskmatrix.operator.${operator}`),
            value: operator,
          }))}
          emptyLabel={`${t('common:select')} ${t('common:operator')}`}
          onchange={onOperatorChange}
          required
        />
      </Grid>
      <Grid xs={3}>
        {(!type || type === 'string') && (
          <TextInputForm
            name={`params.${index}.value`}
            control={control}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
        {type === 'list' && (
          <TagsInputForm
            name={`params.${index}.value`}
            control={control}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
        {type === 'custom_list' && (
          <SelectListInputForm
            name={`params.${index}.value`}
            control={control}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            type="neutral"
            context="riskMatrix"
            required
          />
        )}
        {type === 'number' && (
          <TextInputForm
            name={`params.${index}.value`}
            control={control}
            type="number"
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
        {type === 'number_range' && <RangeNumberInputForm name={`params.${index}.value`} control={control} required />}
        {type === 'boolean' && (
          <SelectInputForm
            name={`params.${index}.value`}
            control={control}
            options={[
              { label: 'True', value: true },
              { label: 'False', value: false },
            ]}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
        {type === 'list_sizes_descriptions' && (
          <SelectInputForm
            name={`params.${index}.value`}
            control={control}
            options={['Micro Empresa', 'Empresa de Pequeno Porte', 'Demais', 'Não informado'].map((size) => ({
              label: size,
              value: size,
            }))}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
        {type === 'list_status' && (
          <SelectInputForm
            name={`params.${index}.value`}
            control={control}
            options={kybStatusEnum.map((status) => ({
              label: t(`common:${status}`),
              value: status,
            }))}
            placeholder={`${t('common:insert')} ${t('common:value')}`}
            required
          />
        )}
      </Grid>
      <Grid xs={1}>
        <TextInputForm
          name={`params.${index}.score`}
          control={control}
          type="number"
          placeholder={`${t('common:insert')} ${t('common:score')}`}
          required
        />
      </Grid>
      <Grid xs={2}>
        <IconButton onClick={() => move(index, index - 1)} disabled={index === 0}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton onClick={() => move(index, index + 1)} disabled={index === total - 1}>
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton onClick={() => remove(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default RiskMatrixParameter
