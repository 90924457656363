import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { cleanParams } from '../../services/builders'

export const caseSlice = createSlice({
  name: 'case',
  initialState: {
    cases: {},
    caseDetails: {},
    caseUser: {},
    userCases: {},
    caseAdminPanel: {},
    filters: {
      page: 0,
      limit: 10,
      offset: 0,
      status: '',
      caseId: '',
      userId: '',
      fromDate: null,
      toDate: '',
      firstName: '',
      lastName: '',
      legalName: '',
      userType: '',
      userKey: '',
      userValue: '',
      transactionKey: '',
      transactionValue: '',
      documentNumber: '',
      isMyCases: false,
    },
  },
  reducers: {
    updateCases: (state, cases) => {
      state.cases = cases.payload
    },
    updateCaseDetails: (state, caseDetails) => {
      state.caseDetails = caseDetails.payload
    },
    updateCaseUser: (state, caseUser) => {
      state.caseUser = caseUser.payload
    },
    updateCasesFilters: (state, filters) => {
      state.filters = filters.payload
    },
    updateUserCases: (state, userCases) => {
      state.userCases = userCases.payload
    },
    updateCaseAdminPanel: (state, caseAdminPanel) => {
      state.caseAdminPanel = caseAdminPanel.payload
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findCases: builder.query({
      query: ({
        emailClient,
        roles,
        limit,
        offset,
        page,
        fromDate,
        toDate,
        statusCases,
        caseId,
        userId,
        firstName,
        lastName,
        legalName,
        userType,
        userKey,
        userValue,
        transactionKey,
        transactionValue,
        documentNumber,
      }) => {
        const filteredParams = cleanParams({
          emailClient,
          assignedRoles: roles,
          limit,
          offset,
          page,
          status: statusCases,
          caseId,
          userId,
          fromDate,
          toDate,
          firstName,
          lastName,
          legalName,
          userType,
          userKey,
          userValue,
          transactionKey,
          transactionValue,
          documentNumber,
        })

        return {
          url: `/caseManagement`,
          params: filteredParams,
        }
      },
    }),
    updateCaseComment: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}/comments`,
        method: 'POST',
        body,
      }),
    }),
    updateCase: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  updateCases,
  updateCaseDetails,
  updateToAssign,
  updateCaseUser,
  updateCasesFilters,
  updateUserCases,
  updateCaseAdminPanel,
  updateCaseAdminToAssing,
} = caseSlice.actions

export default caseSlice.reducer

export const { useFindCasesQuery, useUpdateCaseCommentMutation, useUpdateCaseMutation } = endpoints
